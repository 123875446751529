<template>
  <a-modal
    :visible.sync="show"
    title="添加商品（以下是本网点未上架的商品）"
    destroyOnClose
    width="45%"
    @ok="handleOk"
    @cancel="$emit('update:show', false)"
  >
    <div class="modal-search-bar">
      <a-form-model
        layout="horizontal"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        ref="searchRef"
        :model="searchForm"
      >
        <!-- 产品列表 -->
        <a-row>
          <a-col :span="8">
            <a-form-model-item prop="type" label="分类">
              <a-select v-model="searchForm.type" @change="handleTypeChange"
                placeholder="产品属性" style="width:100%;">
                <a-select-option value>全部</a-select-option>
                <a-select-option
                  v-for="item in Object.keys(goodsType)"
                  :value="item"
                  :key="item"
                >{{goodsType[item]}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item prop="goods_name" label="商品名">
              <a-input allowClear v-model="searchForm.goods_name" placeholder="商品名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button @click="handlerSearch" type="primary">搜索</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table
      ref="goodsTable"
      :columnsData="columns"
      rowKey="goods_id"
      :row-selection="rowSelection"
      :customRow="Rowclick"
      :tableData="tableData"
      :getListFunc="initData"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
      :customHeight="300"
    >
    <div slot="footer">
        新增
        <span class="text-red-500">{{ rowSelection.selectedRowKeys.length }}</span>项
      </div>
    </base-table>
  </a-modal>
</template>

<script>
import { addGoodsList } from "@/api/shop"
import { goodsType, formatGoodsType } from '@/utils/type.js'
import _ from "lodash"
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    typeId: {
      type: [String, Number],
    },
    // selectKeys: {
    //   type: Array,
    // },
    newKeys: {
      type: Array,
    },
    handlerOk: {
      type: Function,
    },
    initList: {
      type: Function,
    },
  },
  data() {
    return {
      goodsType: goodsType,
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },
      searchForm: {
        // goods_number: "",
        goods_name: "",
        type: "",
        page: 1,
        page_count: 300,
      },
      columns: [
        {
          title: "产品属性",
          dataIndex: "goods_type",
          align: "center",
          width: "20%",
          slots: {
            customRender: "type",
          },
          slotsType: "format",
          slotsFunc: (val) => formatGoodsType(val),
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
        },
      ],
      tableData: [],
      total: 0,
      selectRows: [],
      // unSlectRowKeys: [],
      rowSelection: {
        selectedRowKeys: [],
        onSelect: (record, selected) => {
          if (selected) {
            this.rowSelection.selectedRowKeys.unshift(record.goods_id)
            this.selectRows.unshift(record)
          } else {
            this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
              (el) => el != record.goods_id
            )
            // this.unSlectRowKeys.push(record.goods_id)
            this.selectRows = this.selectRows.filter(el => el.goods_id != record.goods_id)
          }
        },
        onSelectAll: (selected, selectedRows) => {
          this.selectRows = this.selectedRows
          if (selected) {
            this.rowSelection.selectedRowKeys = selectedRows.map(
              (el) => el.goods_id
            )
            this.selectRows = selectedRows
          } else {
            this.rowSelection.selectedRowKeys = []
            this.selectRows = []
          }
        },

        getCheckboxProps: (record) => {
          return {
            props: {
              defaultChecked: this.newKeys.includes(record.goods_id),
            },
          }
        },
      },
    }
  },
  mounted() {
    this.searchForm.type = this.typeId
    this.initData()
    this.rowSelection.selectedRowKeys = _.cloneDeep(this.newKeys)
  },
  methods: {
    async initData() {
      let goods_list = []
      if(!this.searchForm.type){
        const res1 = await addGoodsList(this.searchForm, true)
        const res2 = await addGoodsList(this.searchForm, false)
        goods_list = res1.data.list.concat(res2.data.list)
      }else{
        const { data } = await addGoodsList(this.searchForm, this.searchForm.type==3)
        goods_list = data.list
      }
      this.tableData = goods_list.filter((item) => {
        return item.is_add!=2
        // return !this.selectKeys.includes(item.goods_id)
      })
    },
    handleTypeChange(){
      this.handlerSearch()
    },
    // 保存
    handleOk() {
      if(this.selectRows.some(el=>el.goods_type==3)){
        this.$message.info("蛋糕的上下架由城市统一操作")
        return
      }

      this.$emit(
        "ok",
        this.selectRows
      )
    },
    handlerSearch() {
      // this.page = 1
      this.initData()
    },
    resetForm() {
      this.$refs.searchRef.resetFields()
    },

    Rowclick(record) {
      return {
        on: {
          click: () => {
            //如果是已选就去掉
            if (this.rowSelection.selectedRowKeys.includes(record.goods_id)) {
              this.rowSelection.selectedRowKeys = this.rowSelection.selectedRowKeys.filter(
                (el) => el != record.goods_id
              )
              this.selectRows = this.rows.filter(
                (el) => el.goods_id != record.goods_id
              )
            } else {
              this.rowSelection.selectedRowKeys.unshift(record.goods_id)
              this.selectRows.push(record)
            }
          },
        },
      }
    },
  },
}
</script>

<style>
.ant-modal-body {
  position: relative;
}
</style>