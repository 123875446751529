<template>
  <a-modal
    :visible="show"
    v-bind="$attrs"
    title="从其他网点复制商品"
    :afterClose="remove"
    width="700px"
    ok-text="从此店复制全部商品"
    @cancel="remove"
    @ok="handleOk"
  >
    <div>
      <div class="ml-3 mb-2">门店名称：<a-select
        show-search
        style="width: 250px"
        placeholder="输入门店名称搜索"
        :default-active-first-option="false"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        @search="handlerShopChange"
        @change="handlerChange"
        v-model="searchParams.outlets_id"
      >
        <a-select-option
          v-for="(item, index) in outletsList"
          :key="index"
          :value="item.outlets_id"
        >{{ item.shop_name }}</a-select-option>
      </a-select></div>
      
    </div>

    <base-table
      ref="saleGoods"
      id="saleGoods"
      :columnsData="columns"
      rowKey="sku_id"
      :tableData="tableData"
      :total="searchParams.total"
      :getListFunc="initGoodsData"
      :page.sync="searchParams.page"
      :pageSize.sync="searchParams.page_count"
    ></base-table>

    <div class="tips" v-if="onlyCake">* 因本网点是配送站，所以只从目标门店复制蛋糕产品</div>
  </a-modal>
</template>

<script>
import { getSaleGoodsList, syncOutletsData, getSearchShopList } from "@/api/shop.js"
import { formatGoodsType} from '@/utils/type.js'
import { rowSpan } from '@/utils/index'
import { cutQuery } from '@/utils/ossImg'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      onlyCake: false,

      columns: [
        {
          title: "分类",
          dataIndex: "goods_type",
          align: "center",
          width: 90,
          customRender: (text, row) => {
            return {
              children: formatGoodsType(text),
              attrs: {
                rowSpan: row.goods_nameRowSpan,//需要合并的数据
              }
            }
          }
        },
        {
          title: "图片",
          dataIndex: "cover_url",
          align: "center",
          width: 80,
          customRender: (text, row) => {
            if(text) text = text+cutQuery(50,50)
            return {
              children: <img style="height:50px;" src={text}></img>,
              attrs: {
                rowSpan: row.goods_nameRowSpan,//需要合并的数据
              }
            }
          }
        },
        {
          title: "商品名称",
          dataIndex: "goods_name",
          align: "center",
          customRender: (text, row) => {
            return {
              children: text,
              attrs: {
                rowSpan: row.goods_nameRowSpan,//需要合并的数据
              }
            }
          }
        },
      ],
      outletsList: [],
      tableData: [],
      searchParams: {
        page: 1,
        page_count: 40,
        outlets_id: undefined,
      }
    }
  },
  mounted() {
    let outletsInfo = localStorage.getItem('outletsInfo')
    if(outletsInfo){
      try{
        outletsInfo = JSON.parse(outletsInfo)
        if(outletsInfo){
          this.onlyCake = outletsInfo.outlets_type == 2
        }
      }catch(err){
        console.log(err)
      }
    }

    if(this.onlyCake){
      this.searchParams.goods_type = 3
    }else{
      this.searchParams.goods_type = ""
    }
  },
  methods: {

    // 搜索门店
    async handlerShopChange(value) {
      const params = {
        shop_name: value,
      }
      if (value) {
        const { data, code } = await getSearchShopList(params)
        if (code === 0) this.outletsList = data.list
      }
    },

    async initGoodsData() {
      const { data, code } = await getSaleGoodsList(this.searchParams)
      if (code == 0) {
        this.searchParams.total = data.total_count
        // 组装表格所需数据
        let skuArr = []
        data.list.map(goods => {
          goods.sku_data.map(item => {
            let obj = goods
            obj.flavor_name = item.flavor_name
            obj.status = 1
            if (item.specification_list.length > 0) {
              item.specification_list.map(el => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })
        skuArr = rowSpan(skuArr, 'goods_name')

        this.tableData = skuArr
      }
    },


    handlerChange() {
      this.initGoodsData()
    },

    remove() {
      this.$emit('update:show', false)
    },
    handleOk() {
      const _this = this
      this.$confirm({
        title: '提示',
        content: '同步后，会完全覆盖本店售卖商品',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          const res1 = await syncOutletsData({ 
            copy_outlets_id: _this.searchParams.outlets_id, 
            isCake: true 
          })

          if(!_this.onlyCake){
            const res2 = await syncOutletsData({ 
              copy_outlets_id: _this.searchParams.outlets_id, 
              isCake: false 
            })
            const code1 = res1 ? res1.code : 0
            const code2 = res2 ? res2.code : 0
            if(code1 == 0 && code2 == 0){
              _this.$message.success('操作成功')
              _this.$emit('update:show', false)
              _this.$parent.initData()
            }
          }else{
            const code1 = res1 ? res1.code : 0
            if(code1 == 0){
              _this.$message.success('操作成功')
              _this.$emit('update:show', false)
              _this.$parent.initData()
            }
          }
        },
        onCancel() {
          console.log('Cancel');
        },
      });

    },
  }
}
</script>
<style lang="less">
.tips {
  margin: 10px auto;
  color: #999;
}
</style>
